import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { AHubEpics } from "epics/ahub.epics";
import { Epic } from "epics/epic";
import { Action } from "redux";
import {
	combineEpics,
	createEpicMiddleware,
	EpicMiddleware,
} from "redux-observable";
import { AHubService } from "services/ahub/ahub.service";
import { AHubServiceUtil } from "services/ahub/ahub.service.util";
import { ObjectStoreService } from "services/object-store/object-store.service";
import { UploadService } from "services/upload/upload.service";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { ComponentEpics } from "./component.epics";
import { AViewEpics } from "./aview.epics";
import { AHubAccountsEpics } from "./ahub-accounts.epics";
import { AHubLibraryEpics } from "./ahub-library.epics";
import { AHubACatalogEpics } from "./ahub-acatalog.epics";
import { AHubIntegrationEpics } from "./ahub-integration.epics";
import { AHubSessionEpics } from "./ahub-session.epics";

@Injectable()
export class RootEpic {
	/**
	 * Instance of each epic we want to link into our larger epic class
	 */
	private readonly aHubEpics: Epic;
	private readonly aHubServiceAccountsEpics: Epic;
	private readonly aHubServiceLibraryEpics: Epic;
	private readonly componentEpics: Epic;
	private readonly aViewEpics: Epic;
	private readonly aCatalogEpics: Epic;
	private readonly integrationEpics: Epic;
	private readonly sessionEpics: Epic;

	constructor(
		private router: Router,
		private httpClient: HttpClient,
		private objectStore: ObjectStoreService
	) {
		//Create a service utils with the Http client
		const aHubServiceUtil: AHubServiceUtil = new AHubServiceUtil(
			this.httpClient
		);
		const aHubService = new AHubService(aHubServiceUtil);

		//Create an instance of each epic class which we want to link into our root epic
		this.aHubEpics = new AHubEpics(
			router,
			aHubService,
			new UploadService(httpClient),
			objectStore,
			this.httpClient
		);
		this.componentEpics = new ComponentEpics(aHubService);
		this.aViewEpics = new AViewEpics(aHubService, this.httpClient);

		this.aCatalogEpics = new AHubACatalogEpics(aHubService);
		this.integrationEpics = new AHubIntegrationEpics(aHubService);

		this.aHubServiceAccountsEpics = new AHubAccountsEpics(aHubService);
		this.aHubServiceLibraryEpics = new AHubLibraryEpics(
			aHubService,
			new UploadService(httpClient),
			objectStore
		);
		this.sessionEpics = new AHubSessionEpics(aHubService, router);
	}

	/**
	 * Create middleware which contains all the epics
	 */
	epicMiddlewareGet(): EpicMiddleware<Action<any>, Action<any>, void, any> {
		//Create the epic middleware for the rxjs-observable
		return createEpicMiddleware();
	}

	/**
	 * Initalsise the epic middleware with our epics
	 */
	epicMiddlewareInitialise(
		epicMiddleware: EpicMiddleware<Action<any>, Action<any>, void, any>
	) {
		//If we are not in production we will be running some tests
		//on our epics to try and prevent issues were they may have.
		//OK SO EPICS WILL NOT RE-FIRE IF A CATCH BLOCK IS CALLED IN THE ROOT STREAM OF THE EPIC
		//THIS IS QUITE A BIG ISSUE IF THIS HAPPENS AS THE APP WILL FAIL TO RESPOND.
		//SO THIS FUNCTION IS SIMPLY DESIGNED TO WARN US IN THE ERROR LOG IF A CATCH BLOCK EXISTS
		if (environment.production == false) {
			this.epicFunctionCatchTest([
				...this.aHubEpics.epicMethods(),
				...this.aHubServiceAccountsEpics.epicMethods(),
				...this.aHubServiceLibraryEpics.epicMethods(),
				...this.componentEpics.epicMethods(),
				...this.aViewEpics.epicMethods(),
				...this.aCatalogEpics.epicMethods(),
				...this.integrationEpics.epicMethods(),
				...this.sessionEpics.epicMethods(),
			]);
		}

		//Create the root epic, specifing the epic fuctions to combine
		//We also have an uncaught exceptions catch
		const rootEpic = (action$, store) =>
			combineEpics(
				...this.aHubEpics.epicMethods(),
				...this.aHubServiceAccountsEpics.epicMethods(),
				...this.aHubServiceLibraryEpics.epicMethods(),
				...this.componentEpics.epicMethods(),
				...this.aViewEpics.epicMethods(),
				...this.aCatalogEpics.epicMethods(),
				...this.integrationEpics.epicMethods(),
				...this.sessionEpics.epicMethods()
			)(action$, store).pipe(
				catchError((error, stream) => {
					//BOOM! the stream broke and the error was not caught

					//If were not in production we will print a message
					if (environment.production == false) {
						//Error message we will display
						let errorMessage = null;

						//Test if the error message
						try {
							errorMessage = JSON.stringify(error);
						} catch (badParseError) {
							errorMessage = error;
						}

						//Log the error to the console.
						console.error("Uncaught Epic Error", errorMessage);
					}

					//\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/
					//MOST IMPORTANT
					///\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\
					//We must return the stream at this point. If we don't
					//the stream will go somewhere to die and will not react to
					//any further actions
					return stream;
				})
			);

		//Run the root epic in our middleware
		epicMiddleware.run(rootEpic);

		// //Create our epic as a piece of middleware which can be used with the store
		return epicMiddleware;
	}

	/**
	 * Test for catches at the end of the main stream in epic functions
	 *
	 * OK SO EPICS WILL NOT RE-FIRE IF A CATCH BLOCK IS CALLED IN THE ROOT STREAM OF THE EPIC
	 * THIS IS QUITE A BIG ISSUE IF THIS HAPPENS AS THE APP WILL FAIL TO RESPOND.
	 * SO THIS FUNCTION IS SIMPLY DESIGNED TO WARN US IN THE ERROR LOG IF A CATCH BLOCK EXISTS
	 *
	 * @param epicFunctions
	 */
	epicFunctionCatchTest(epicFunctions: Function[]) {
		//OK SO EPICS WILL NOT RE-FIRE IF A CATCH BLOCK IS CALLED IN THE ROOT STREAM OF THE EPIC
		//THIS IS QUITE A BIG ISSUE IF THIS HAPPENS AS THE APP WILL FAIL TO RESPOND.
		//SO THIS FUNCTION IS SIMPLY DESIGNED TO WARN US IN THE ERROR LOG IF A CATCH BLOCK EXISTS

		//Set the level
		let level = 0;

		//OK we want to loop throuh each epic function
		for (
			let epicFunctionIndex = 0;
			epicFunctionIndex < epicFunctions.length;
			epicFunctionIndex++
		) {
			//Get the epic function from the array
			const epicFunction = epicFunctions[epicFunctionIndex];

			//Get the epic string
			let epicString = epicFunction.toString();

			//Loop through each of the charaters in the epic string backwards so we can remove thoes that we don't want
			for (let charI = epicString.length - 1; charI >= 0; charI--) {
				const char = epicString.charAt(charI);
				if (char == ")") {
					level++;
				} else if (char == "(") {
					level--;
				} else if (level > 0) {
					epicString =
						epicString.substr(0, charI) + epicString.substr(charI + 1);
				}
			}

			//OK now we have stripped out the
			if (epicString.indexOf(".catch") > -1) {
				let message =
					"Miss-used Catch? It appears that there is a catch contained in your main epic stream e.g. outside of merge maps. ";
				message +=
					"We belive this to be bad as epic streams will not refire after an error has been caught. ";
				message +=
					"If your wish to catch an error I sould sugest doing so inside a new stream inside a merge map\n\n";
				message += epicFunction.toString();

				console.error(message);
			}
		}
	}
}
